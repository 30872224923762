.ProcessingBackupContainer {
  text-align: center;
  padding-top: 176px;
  color: #ffffff;
}

.ProccessingSummary {
  font-size: 80px;
  letter-spacing: -2px;
  margin-top: 10px;
  font-family: "Raleway-Bold";
}

.CurrProccessing {
  font-family: "Raleway";
  letter-spacing: -0.4px;
  font-size: 16px;
  margin-top: 44px;
}

.ProccessingDesc {
  font-size: 22px;
  width: 408px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Raleway-Medium";
  letter-spacing: 0.55px;
  margin-top: 19px;
}

.readingListsTitle {
  font-size: 80px;
  letter-spacing: -2px;
  margin-top: 10px;
  font-family: "Raleway";
}

.readingListsDesc {
  font-size: 22px;
  font-family: "Raleway-Medium";
  letter-spacing: 0.55px;
  margin-top: 19px;
}

.ProccessingProgress {
  margin-top: 15px;
}

@media (max-width: 1100px) {
  .ProcessingBackupContainer {
    padding-top: 81px;
  }
  .MainProcessingImage {
    zoom: 0.73;
  }

  .readingListsTitle {
    font-size: 48px;
    letter-spacing: -1.2px;
    margin-top: 10px;
  }

  .readingListsDesc {
    font-size: 18px;
    letter-spacing: 0px;
  }

  .ProccessingSummary {
    font-size: 60px;
    letter-spacing: -1.5px;
  }

  .ProccessingDesc {
    font-size: 18px;
    width: 289px;
    letter-spacing: 0px;
  }
}
