body {
  margin: 0;
}

@font-face {
  font-family: "Raleway-Bold";
  src: url("./fonts/Raleway-Bold.ttf");
}

@font-face {
  font-family: "Raleway-ExtraBold";
  src: url("./fonts/Raleway-ExtraBold.ttf");
}

@font-face {
  font-family: "Raleway-SemiBoldItalic";
  src: url("./fonts/Raleway-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Raleway-Italic";
  src: url("./fonts/Raleway-Italic.ttf");
}

@font-face {
  font-family: "Raleway-LightItalic";
  src: url("./fonts/Raleway-LightItalic.ttf");
}

@font-face {
  font-family: "Raleway-Light";
  src: url("./fonts/Raleway-Light.ttf");
}

@font-face {
  font-family: "Raleway-Regular";
  src: url("./fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Raleway-Medium.ttf";
  src: url("./fonts/Raleway-Medium.ttf");
}

.ContentContainer {
  width: 1028px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 17px;
}

.AppBackground {
  background: linear-gradient(
    to left,
    rgb(24, 62, 213) 0%,
    rgb(19, 102, 220) 71%,
    rgb(12, 158, 229) 91%,
    rgb(9, 183, 233) 100%
  );

  height: auto;
}

.FullPageContainer {
  min-height: 844px;
}

@media (max-width: 1100px) {
  .FullPageContainer {
    min-height: 590px;
    height: 100vh;
  }
  .ContentContainer {
    width: auto;
    padding-top: 9px;
  }
}
