.fixLeft {
  position: fixed;
  left: -36vw;
  opacity: 0.1;
  display: none;
}

.fixRight {
  position: fixed;
  right: -29vw;
  opacity: 0.1;
  display: none;
}
