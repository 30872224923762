.ProcessingCompleteContainer {
  text-align: center;
  padding-top: 74px;
  color: #ffffff;
}

.CompleteTitle {
  letter-spacing: -2px;
  font-size: 80px;
  font-family: "Raleway-ExtraBold";
  margin-top: 19px;
}

.CompleteDesc {
  letter-spacing: 0.55px;
  font-size: 22px;
  font-family: "Raleway";
  margin-top: 19px;
  width: 497px;
  margin-left: auto;
  margin-right: auto;
}

.CompleteLink {
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 504px;
  height: 61px;
  line-height: 61px;
  border: 1px solid #ffffff;
  border-radius: 48px;
  color: #00fff6;
  font-size: 17px;
  letter-spacing: 0.42px;
  text-align: center;
  font-family: "Raleway-Light";
  background: transparent;
  outline: none;
}

.linkExperationDesc {
  letter-spacing: 0.55px;
  font-size: 14px;
  font-family: "Raleway";
  margin-top: 31px;
}

.CompletedBtnsContainer {
  display: flex;
  width: 175px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7px;
}

.CompleteButton {
  width: 61px;
  height: 61px;
  border: 3px solid #00fff6;
  margin-top: 21px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 33px;
}

.CopyLinkButton {
  margin-right: 16px;
}

.CompleteButton:hover {
  cursor: pointer;
  background-color: #00fff6;
}

.CopyLinkButtonLogo {
  background: url("./imgs/CopyBtnBlue.svg") no-repeat;
  width: 25px;
  height: 25px;
  margin-top: 19px;
  margin-left: 19px;
}

.CopyLinkButton:hover .CopyLinkButtonLogo {
  background: url("./imgs/CopyBtnBlack.svg") no-repeat;
}

.DownloadBuckupButtonLogo {
  background: url("./imgs/DownloadBtnBlue.svg") no-repeat;
  width: 25px;
  height: 25px;
  margin-top: 21px;
  margin-left: 20px;
}

.DownloadBuckupButton:hover .DownloadBuckupButtonLogo {
  background: url("./imgs/DownloadBtnBlack.svg") no-repeat;
}

@media (max-width: 1100px) {
  .ProcessingCompleteContainer {
    padding-top: 34px;
  }

  .CompleteImage {
    zoom: 0.73;
  }

  .CompleteTitle {
    letter-spacing: -1.5px;
    font-size: 60px;
  }

  .CompleteDesc {
    letter-spacing: 0px;
    font-size: 18px;
    width: 306px;
  }

  .CompleteLink {
    width: 85vw;
    max-width: 504px;
    font-size: 16px;
    letter-spacing: 00px;
  }
}
