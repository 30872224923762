.FotterContainer {
  height: 52px;
  background: #000000 0% 0% no-repeat padding-box;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.FotterLogo {
  margin-top: -3px;
}
