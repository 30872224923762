.ExplanationContainer {
  height: auto;
  margin-bottom: 130px;
}

.ExplanationTitle {
  font-size: 22px;
  letter-spacing: 0px;
  color: #223f5b;
  font-family: "Raleway-Bold";
  margin-bottom: 8px;
  margin-top: 45px;
}

.ExplanationDesc {
  width: 575px;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0px;
  font-family: "Raleway-Regular";
  margin-bottom: 9px;
  color: #223f5b;
}

.ExplanationStep,
.ExplanationQustionHeader,
.ExplanationAnswerHeader {
  color: #223f5b;
  font-size: 22px;
  text-align: left;
  font-family: "Raleway-Bold";
  float: left;
}

.ExplanationTabDesc {
  margin-top: 15px;
  margin-left: 27px;
}

.ExplanationQuestion {
  font-family: "Raleway-Bold";
}

@media (max-width: 1100px) {
  .ExplanationContainer {
    margin-left: 8vw;
    margin-right: 3vw;
  }

  .ExplanationDesc {
    width: auto;
  }
}
