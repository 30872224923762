.ProgressBarContainer {
  box-shadow: 0px 32px 9px #00000029;
  border-radius: 23px;
  width: 480px;
  height: 20px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.ProgressBarFiller {
  background-color: #ffe600;
  -webkit-transition: width 2s ease;
  -moz-transition: width 2s ease;
  -o-transition: width 2s ease;
  transition: width 2s ease;
}

@media (max-width: 1100px) {
  .ProgressBarContainer {
    width: 75vw;
    margin-top: 20px;
  }
}
