.userName {
  font-size: 22px;
  text-align: center;
  font-family: "Raleway-Regular";
  letter-spacing: -0.55px;
  color: #ffffff;
  padding-top: 136px;
}

.ExportSettingsExplenation {
  font-size: 38px;
  text-align: center;
  font-family: "Raleway-Regular";
  letter-spacing: -0.95px;
  color: #ffffff;
  padding-top: 6px;
}

.ExportLibraryButton {
  width: 310px;
  height: 61px;
  line-height: 61px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 51px;
  border: 2px solid #00fff6;
  border-radius: 51px;
  font-size: 17px;
  text-align: center;
  font-family: "Raleway-Bold";
  letter-spacing: 0.42px;
  color: #00fff6;
  text-transform: uppercase;
}

.ExportLibraryButton:hover {
  background: #00fff6 0% 0% no-repeat padding-box;
  color: black;
  cursor: pointer;
}

.ExportLibraryButton:hover .ExportButtonLogo {
  background: url("./imgs/ExportLogo.svg") no-repeat;
}

.ExportButtonLogo {
  background: url("./imgs/ExportLogoBlue.svg") no-repeat;
  float: left;
  width: 21px;
  height: 21px;
  margin-top: 19px;
  margin-left: 33px;
  margin-right: -21px;
}

.ExportSettingsOr {
  font-size: 22px;
  text-align: center;
  font-family: "Raleway-Regular";
  letter-spacing: 0.55px;
  color: #ffffff;
  margin-top: 15px;
}

.ExportUrlInput {
  width: 504px;
  height: 61px;
  line-height: 61px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 21px;
  border: 1px solid #ffffff;
  border-radius: 48px;
  font-size: 17px;
  font-family: "Raleway-Regular";
  text-align: left;
  padding-left: 5px;
  letter-spacing: 0.82px;
  background: transparent;
  outline: none;
  color: #ffe600;
  display: block;
}

.ExportUrlInput:hover {
  border-color: #00fff6;
  cursor: pointer;
}

.ExportUrlInput:focus {
  border-color: #ffe600;
}

.ExportUrlInput::-ms-input-placeholder {
  font-family: "Raleway-Regular";
  color: #00fff6;
  text-align: center;
}

.ExportUrlInput::placeholder {
  color: #00fff6;
  text-align: center;
}

.ExportLinkButton {
  width: 61px;
  height: 61px;
  border: 3px solid #ffe600;
  margin-top: 11px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 33px;
}

.ExportLinkButton:hover {
  cursor: pointer;
  background-color: #ffe600;
}

.ExportLinkButton:hover .ExportLinkButtonImg {
  background: url("./imgs/ExportLogo.svg") no-repeat;
}

.ExportLinkButtonImg {
  background: url("./imgs/ExportLogoYellow.svg") no-repeat;
  width: 21px;
  height: 21px;
  margin-top: 19px;
  margin-left: 21px;
}

@media (max-width: 1100px) {
  .userName {
    display: none;
  }

  .ExportSettingsExplenation {
    font-size: 28px;
    letter-spacing: -0.7px;
    padding-top: 97px;
  }

  .ExportUrlInput {
    width: 310px;
    padding-bottom: ;
  }

  .ExportLinkButton {
    margin-top: 25px;
  }
}
