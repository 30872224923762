.Main {
  height: 422px;
  padding-top: 24px;
}
h1 {
  float: left;
  font-family: Raleway-ExtraBold;
  letter-spacing: -3.08px;
  text-shadow: 0px 10px 14px #00000029;
  font-size: 124px;
  color: white;
  margin: 0px;
  margin-left: 3px;
}

.h1Part1 {
}

.h1Part2 {
  margin-top: -73px;
  margin-left: 7px;
}

.h1Part3 {
  font-size: 146px;
  letter-spacing: -7.3px;
  text-shadow: 0px 20px 14px #00000029;
  font-family: Raleway-LightItalic;
  margin-top: -79px;
  margin-left: 170px;
}

.RightContainer {
  float: right;
  margin-right: 110px;
}

h2 {
  color: white;
  font-size: 22px;
  font-family: Raleway-Bold;
  text-align: center;
  margin-top: 44px;
  margin-bottom: 45px;
}

.MainButton {
  width: 310px;
  height: 61px;
  line-height: 61px;
  background: #ffe600 0% 0% no-repeat padding-box;
  border-radius: 51px;
  text-align: center;
  font-size: 17px;
  font-family: Raleway-Bold;
  letter-spacing: 0.42px;
  color: #414141;
  text-transform: uppercase;
  margin-top: 17px;
}

.MainButton:hover {
  cursor: pointer;
  background: #00fff6 0% 0% no-repeat padding-box;
}

.MainButtonLogo {
  vertical-align: middle;
}

@media (max-width: 1100px) {
  .Main {
    height: 558px;
  }

  h1 {
    float: none;
    width: 270px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: -2px;
    font-size: 80px;
  }

  .h1Part1 {
    margin-left: -20px;
  }
  .h1Part2 {
    margin-top: -48px;
    margin-left: -13px;
  }

  .h1Part3 {
    font-size: 94px;
    color: #ffffff;
    margin-top: -54px;
    margin-left: 96px;
  }

  .RightContainer {
    float: none;
    margin-right: 0px;
  }

  h2 {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .MainButton {
    margin-left: auto;
    margin-right: auto;
  }
}
