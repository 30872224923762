.mainNav {
  height: 73px;
  background: #000000;
}

.MainLogo:hover {
  cursor: pointer;
}

.HeaderLink {
  color: #ffffff;
  font-size: 14px;
  float: right;
  font-family: Raleway-Bold;
  margin-left: 35px;
  margin-top: 25px;
}

.HeaderLink:hover {
  cursor: pointer;
  color: #00fff6;
  z-index: 5;
}

@media (max-width: 1100px) {
  .mainNav {
    height: 60px;
  }

  .HeaderLink {
    display: none;
  }

  .MainLogo {
    margin-left: 21px;
  }
}
