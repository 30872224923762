.ImportSettingsContianer {
  text-align: center;
  padding-top: 106px;
  color: #ffffff;
}

.ImportSettingsTitle {
  font-size: 22px;
  letter-spacing: 0.55px;
  font-family: "Raleway-Medium";
  margin-top: 25px;
  width: 315px;
  margin-left: auto;
  margin-right: auto;
}

.dragArea {
  margin-top: 26px;
  width: 480px;
  height: 194px;
  border: 4px dashed #00fff6;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
}

.dragArea:hover {
  border-color: #ffe600;
  cursor: pointer;
}

.dragArea:hover .ImportDragAreaDesc {
  color: #ffe600;
}

.dragArea:hover .dragImageContainer {
  border-color: #ffe600;
  background-color: #ffe600;
}

.dragArea:hover .dragImage {
  background: url("./imgs/ImportLogo.svg") no-repeat;
}

.importSubmitbutton,
.importFileInput {
  display: none;
}

.ImportDragAreaDesc {
  padding-top: 46px;
  font-size: 28px;
  letter-spacing: 0px;
  color: #00fff6;
  font-family: "Raleway-Regular";
}

.dragImageContainer {
  width: 61px;
  height: 61px;
  border: 3px solid #00fff6;
  opacity: 1;
  margin-top: 11px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 33px;
}

.dragImage {
  background: url("./imgs/ImportBlueLogo.svg") no-repeat;
  width: 21px;
  height: 21px;
  margin-top: 19px;
  margin-left: 21px;
}

.UploadButton {
  display: none;
}

@media (max-width: 1100px) {
  .ImportSettingImage {
    zoom: 0.73;
  }

  .ImportSettingsContianer {
    padding-top: 72px;
  }

  .ImportSettingsTitle {
    font-size: 18px;
    letter-spacing: 0px;
  }

  .dragArea {
    display: none;
  }

  .UploadButton {
    display: block;
    width: 310px;
    height: 61px;
    line-height: 61px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 51px;
    border: 2px solid #00fff6;
    border-radius: 51px;
    font-size: 17px;
    text-align: center;
    font-family: "Raleway-Bold";
    letter-spacing: 0.42px;
    color: #00fff6;
    text-transform: uppercase;
  }

  .UploadButtonImage {
    float: left;
    margin-top: 19px;
    margin-left: 25px;
    margin-right: -21px;
  }
}
